<template>
  <project-table></project-table>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ProjectTable from '../../components/JobTable.vue';

export default defineComponent({
  name: 'Jobs',
  components: {
    'project-table': ProjectTable
  }
});
</script>

<template>
  <div class="job-container">
    <div class="job-heading">
      <div class="job-title">
        Übersicht PAUL Reports
        <div class="buttons">
          <img @click="newJob" src="/images/add.png"/>
          <img @click="getJobsData(), refresh = true" :class="{'refresh-icon': refresh}" src="/images/reload.png"/>
        </div>
      </div>
      <div class="filter-container">
        <div class="job-filter">
          <DatePicker v-model="searchDate" locale="de" placeholder="Startdatum" is-range :masks="{ navMonths: 'MMM'}">
              <template v-slot="{ inputValue, inputEvents }">
                <div class="container" :value="inputValue"
                  v-on="inputEvents.start">
                  <input
                  id="date"
                  class="search-input"
                  :class="{ 'border-red-600': errorMessage }"
                  :value="inputValue.start"
                  placeholder="Startdatum"
                  @input="filterByDate()"
                >
                </div>
              </template>
            </DatePicker>
        </div>
        <div class="job-filter search-container">
          <input type="text" placeholder="Name" class="search-input" v-model="name" @input="filterByName()">
        </div>
        <div class="job-filter search-container">
          <input type="text" placeholder="Projektnummer" class="search-input" v-model="contract_number" @input="filterByNumber()">
        </div>
        <div class="job-filter">
          <Multiselect
            v-model="statusFilter.value"
            v-bind="statusFilter"
            @select="filterByStatus()"
            @deselect="filterByStatus()"
          >
            <template v-slot:multiplelabel="{ values }">
              <div class="multiselect-multiple-label">
                {{ values.length }} ausgewält
              </div>
            </template>

            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon"> {{ option.name }}
            </template>
          </Multiselect>
        </div>
      </div>
    </div>

    <div :class="{'show-details': showDetails}">
      <div class="job-datatable">
        <div class="job-table" id="left" :class="{'left': showDetails}">
          <div class="table-head">
            <div class="table-row">
              <div class="table-column" v-for="field in columns" :key="field">
                <div class="column-sortable" v-if="field.sortable"  @click="sort(field.name)">
                  <div class="column-sortable-icons">
                    <img v-if="currentSort.field !== field.name" src="/images/arrows.png" />
                    <img v-if="currentSort.field === field.name && currentSort.direction === 'asc'" src="/images/arrows_asc.png" />
                    <img v-if="currentSort.field === field.name && currentSort.direction === 'desc'" src="/images/arrows_desc.png" />
                    <span class="column-title">{{ field.text }}</span>
                  </div>
                </div>
                <div v-else class="column">
                  <div class="column-title">{{ field.text }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="table-body">
            <div class="table-row" v-for="(job, index) in jobsData" :key="index" :class="{'table-row-active': selected == job._id}">
              <div class="table-created-at" >
                <span class="table-column" :style="{'padding-right': showDetails? '20px' : '52px'}">{{ formatDate(job.created_at) }}</span>
                <span class="table-column">{{ formatTime(job.created_at) }}</span>
              </div>
              <span class="table-column">{{ job.name }}</span>
              <span class="table-column">{{ job.contract_numbers }}</span>
              <div class="table-column status">
                <div v-if="job.status == 1" ><img src="/images/icon_status_20_20/new.png" /> <span>Neu</span></div>
                <div v-if="job.status == 2" ><img src="/images/icon_status_20_20/in_work.png" /> <span>In Arbeit</span></div>
                <div v-if="job.status == 3" ><img src="/images/icon_status_20_20/warning.png" /> <span>Warnung</span></div>
                <div v-if="job.status == 4" ><img src="/images/icon_status_20_20/error.png" /> <span>Fehler</span></div>
                <div v-if="job.status == 5" ><img src="/images/icon_status_20_20/done.png" /> <span>Erledigt</span></div>
                <div v-if="job.status == 6" ><img src="/images/icon_status_20_20/disabled.png" /> <span>Deaktiviert</span></div>
              </div>
              <div v-if="+job.month < 10" class="table-column date">0{{ job.month }}.{{ job.year}}</div>
              <div v-else-if="job.month" class="table-column date">{{ job.month }}.{{ job.year}}</div>
              <div v-else class="table-column date"></div>
              <div class="table-column actions">
                <div>
                  <div v-if="selected == job._id">
                    <img src="/images/details_unactive.png" alt="Details"/>
                  </div>
                  <div v-else class="tooltip">
                    <img @click="showDetails = true, writeInfo(jobsData[index]), selected = job._id" src="/images/details.png" alt="Details"/>
                    <div class="top">
                      <p>
                        Details
                      </p>
                      <i></i>
                    </div>
                  </div>
                </div>
                <div v-if="job.status == 1" class="tooltip delete">
                  <img @click="removeJob(jobsData[index])" src="/images/delete.png" alt="Delete"/>
                  <div class="top">
                    <p>Löschen</p>
                    <i></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showDetails" class="details-panel" id="right">
        <div @mousedown="resizeBlock()" id="drag"></div>
          <div class="details-head">
            <span class="details-title">Details</span>
            <img @click="showDetails = false, selected = '', changeWidth()" src="/images/exit.png" alt="Exit" class="icon-exit">
          </div>
          <div class="details-body">
            <div class="details-item">
              <p class="title">Name:</p>
              <p class="item">{{this.jobDetails.name}}</p>
            </div>
            <div class="details-item">
              <p class="title">Gesamtzahl der Messungen:</p>
              <p class="item">{{this.jobDetails.number_of_measurements}}</p>
            </div>
            <div class="details-item">
              <span class="title">Datum:</span>
              <span v-if="+this.jobDetails.month < 10" class="item"> 0{{ this.jobDetails.month }}.{{ this.jobDetails.year}}</span>
              <span v-else-if="this.jobDetails.month" class="item"> {{ this.jobDetails.month }}.{{ this.jobDetails.year}}</span>
            </div>
            <div class="details-item" v-if="this.jobDetails.error_log.length !== 0" >
              <p class="title">Fehler:</p>
              <div v-for="(value) in this.jobDetails.error_log" :key="value">
                <p class="item">{{ value }}</p>
              </div>
            </div>
            <div class="details-item" v-if="this.jobDetails.number_of_measurements > 0 && this.jobDetails.status == 5">
              <button @click="downloadReport(this.jobDetails._id, this.jobDetails.name)">Download</button>
            </div>
            <div class="details-item warning-buttons" v-if="jobDetails.status == 3">
              <button @click="updateWarningJob({...this.jobDetails, force: true}, 2)">Ok</button>
              <button @click="updateWarningJob({...this.jobDetails, force: false}, 6)">Cancel</button>
            </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="status-panel">
          <div class="item" v-for="item in statusFilter.options" :key="item">
            <img :src=item.icon />
            <span class="item-count">{{item.count}}</span>
            <span>{{item.name}}</span>
          </div>
      </div>
      <div class="pagination">
        <div class="pagination-limit">
          <span>Pro Seite:</span>
          <button @click="changeLimit(limits[0])">{{limits[0]}}</button>
          <span>,</span>
          <button @click="changeLimit(limits[1])">{{limits[1]}}</button>
          <span>,</span>
          <button @click="changeLimit(limits[2])">{{limits[2]}}</button>
        </div>
        <div class="pagination-list">
          <img @click="goBack()" src="/images/button_right.png" alt="Left" class="button-left">
          <span class="current-page">{{currentPage}}</span>
          <div class="round-range" @click="showSlider = !showSlider">
            <img src="/images/arrows_desc.png" alt="desc" class="round-range-slider">
            <span class="round-range-slider-title">...</span>
          </div>
          <span>{{allPages}}</span>
          <img @click="goForward()" src="/images/button_right.png" alt="Right" class="button-right">
        </div>
      </div>
    </div>
    <div class="slider" v-if="showSlider">
      <div class="slidecontainer">
        <input v-on:mouseup="changePage()" v-model="currentPage" type="range" min="1" :max="allPages" class="slider" id="myRange">
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash';
import Multiselect from '@vueform/multiselect';
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';
import { DatePicker } from 'v-calendar';
import { isEmpty, toInteger } from 'lodash';

export default {
  name: 'ProjectTable',
  components: {
    Multiselect,
    DatePicker
  },
  data() {
    return {
      refresh: false,
      selected: "",
      currentPage: 1,
      allPages: 1,
      showSlider: false,
      limits: [10, 20, 50],
      jobDetails: {},
      showDetails: false,
      searchDate: {
        start: null,
        end: null,
      },
      contract_number: null,
      name: null,
      searchData: {
        status: null,
      },
      filterData: {
        limit: 10,
      },
      columns: [
        { name: 'created_at', text: 'Startdatum und -uhrzeit', sortable: true },
        { name: 'name', text: 'Name (Name Chili file)', sortable: true },
        { name: 'contract_numbers', text: 'Projektnummern'},
        { name: 'status', text: 'Status', sortable: true },
        { name: 'date', text: 'Datum'},
        { name: 'details', text: 'Details' }
      ],
      selectedUserId: '',
      currentProjectId: '',
      editProject: false,
      statuses: [],
      jobsData: [],
      entries: [],
      filteredEntries: [],
      currentSort: {
        field: '',
        direction: ''
      },
      unassigned: 'Nicht zugeordnet',
      statusFilter: {
        mode: 'multiple',
        value: null,
        placeholder: 'Status',
        options: [
          { value: '1', name: 'Neu', icon: '/images/icon_status_20_20/new.png', count: 0},
          { value: '2', name: 'In Arbeit', icon: '/images/icon_status_20_20/in_work.png', count: 0},
          { value: '3', name: 'Warnung', icon: '/images/icon_status_20_20/warning.png', count: 0},
          { value: '4', name: 'Fehler', icon: '/images/icon_status_20_20/error.png', count: 0},
          { value: '5', name: 'Erledigt', icon: '/images/icon_status_20_20/done.png', count: 0},
          { value: '6', name: 'Deaktiviert', icon: '/images/icon_status_20_20/disabled.png', count: 0},
        ],
      },
    }
  },
  watch: {
    searchDate: {
      handler(val) {
        if(val) {
          this.filterByDate();
        } else if(this.searchDate.start == null) {
          delete this.filterData.start;
          delete this.filterData.end;
          this.filterByDate();
        }
      },
      deep: true
    },
    name: function(val) {
      if(this.name == '') {
        delete this.filterData.name;
        this.filterByName()
      }
    },
    contract_number: function(val) {
      if(this.contract_number == '') {
        delete this.filterData.contract_number;
        this.filterByNumber();
      }
    },
  },
  computed: {
    ...mapGetters(['getData']),
    tableHead() {
      return this.columns;
    },
    jobs() {
      return this.filteredEntries;
    },
    pages() {
      return this.allPages;
    },
    selectedPage() {
      return this.currentPage;
    }
  },
  created() {
    this.getJobsData();
  },
  methods: {
    ...mapActions(['getJobs', 'updateJob', "download"]),
    formatDate(data) {
      return moment(data).format('DD.MM.YYYY');
    },
    formatTime(data) {
      return moment(data).format('HH:mm');
    },
    newJob() {
      this.$router.push('/jobs/job')
    },
    writeInfo(job) {
      this.jobDetails = job;
    },
    removeJob(data) {
      this.$store.dispatch('removeJob', data).then(() => {
        this.getJobsData();
      })
    },
    changeWidth() {
      let left = document.getElementById("left");
      left.style.width = "100%";
    },
    updateWarningJob(data, updateStatus) {
      console.log(updateStatus);
      data.status = updateStatus;
      for(let i = 0; i < this.jobsData.length; i++) {
        if(this.jobsData[i]._id === data._id) {
          this.jobsData[i].status = updateStatus;
        }
      }
      this.updateJob(data);
    },
    downloadReport(id, name) {
      this.download({id, name});
    },
    getJobsData() {
      this.getJobs(this.filterData).then(() => {
      this.jobsData = this.getData.jobs;
      if(this.filterData.limit == 10) {
        this.allPages = Math.ceil(this.getData.total / this.limits[0])
      } else if(this.filterData.limit == 20) {
        this.allPages = Math.ceil(this.getData.total / this.limits[1])
      } else {
        this.allPages = Math.ceil(this.getData.total / this.limits[2])
      }
      for(let i = 0; i < this.getData.statuses.length; i++) {
        for(let j = 0; j < this.statusFilter.options.length; j++) {
          if(this.getData.statuses[i].status == this.statusFilter.options[j].value) {
            this.statusFilter.options[j].count = this.getData.statuses[i].count;
          }
        }
      }
      setTimeout(() => {
        this.refresh = false;
      }, 3000);
    })
    },
    resizeBlock() {
      let isResizing = true,
        lastDownX = 0;

    (function() {
      let container = document.getElementsByClassName("show-details"),
          left = document.getElementById("left"),
          right = document.getElementById("right"),
          handle = document.getElementById("drag");
      handle.onmousedown = function(e) {
        lastDownX = e.clientX;
      };
      document.onmousemove = function(e) {
        if (!isResizing) {
          return;
        }
        let offsetRight = container[0].clientWidth - (e.clientX - container[0].offsetLeft);
        left.style.width = "calc(100% - " + offsetRight + "px)"; 
        right.style.width = (offsetRight - 10) + "px"; 
      }
      document.onmouseup = function(e) {
        isResizing = false;
      }
    })();
    },
    returnFirstPage() {
      this.currentPage = 1;
      this.filterData.offset = 0;
    },
    changeLimit(limit) {
      this.filterData.limit = limit
      this.returnFirstPage();
      this.getFilteredJobs()
      this.allPages = Math.ceil(this.getData.total / limit)
      this.getJobsData();
    },
    getFilteredJobs() {
      this.getJobs(this.filterData).then(() => {
        this.jobsData = this.getData.jobs;

        this.allPages = Math.ceil(this.getData.total / this.filterData.limit)
        if (this.allPages === 0) {
          this.allPages = 1;
        }
      });
    },
    changePage(){
      this.filterData.offset = this.filterData.limit * (this.currentPage - 1);
      this.getFilteredJobs();
    },
    goBack() {
      if(this.currentPage !== 1) {
        this.currentPage -= 1;
        this.changePage();
      }
    },
    goForward() {
      this.currentPage = parseInt(this.currentPage);
      if(this.currentPage !== this.allPages) {
        this.currentPage += 1;
        this.changePage();
      }
    },
    filterByName() {
      if(this.name) {
        if(this.name.length >= 3) {
          this.filterData.name = this.name;
        }
      }
      this.returnFirstPage();
      this.getFilteredJobs();
    },
    filterByNumber() {
      if(this.contract_number) {
        if(this.contract_number.length >= 3) {
          this.filterData.contract_number = this.contract_number;
        }
      }
      this.returnFirstPage();
      this.getFilteredJobs();
    },
    filterByDate() {
      if(this.searchDate.start && this.searchDate.end) {
        this.filterData.start = moment(this.searchDate.start).format('YYYY-MM-DD');
        this.filterData.end = moment(this.searchDate.end).format('YYYY-MM-DD');
      }
      this.returnFirstPage();
      this.getFilteredJobs();
    },
    sort(name) {
      let direction = 'asc';
      if (name === this.currentSort.field && this.currentSort.direction === 'asc') {
        direction = 'desc';
      }
      this.currentSort = {
        field: name,
        direction: direction
      };
      if(this.currentSort.direction === 'desc') {
        this.filterData.sort = `-${this.currentSort.field}`
      } else {
        this.filterData.sort = this.currentSort.field
      }
      this.returnFirstPage();
      this.getFilteredJobs();
    },
    filterByStatus() {
      const statuses = [];
      this.statusFilter.value.forEach(
          (status) => {
            statuses.push(parseInt(status));
          }
      );
      this.filterData.status = statuses;
      this.returnFirstPage();
      this.getFilteredJobs();
    }
  }
}
</script>

<style lang="scss">
.multiselect-option.is-selected {
    background: #bebebe !important;
    color: #7a7a7a !important;
}

.refresh-icon {
  animation: spin 1s linear 0s infinite reverse;

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
}

.character-option-icon {
  width: 30px;
  height: 30px;
}

.filter-container {
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.job-container {
  font-family: 'Roboto', sans-serif;
  padding-top: 45px;
  margin-left: 45px;
  margin-right: 45px;
  padding-bottom: 100px;

  .job-heading {
    width: 100%;
    background: #ffffff;
    max-width: calc(100% - 90px);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 90px;
    padding-bottom: 40px;
    padding-right: 10px;
    z-index: 8;

    .job-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 478px;
      justify-content: space-between;
      font-weight: 500;
      font-size: 36px;
      color: #022259;
      text-align: left;
      font-style: normal;

      .buttons {
        width: 66px;
        display: flex;
        justify-content: space-between;
        cursor:pointer;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .container {
      position: relative;
      height: 45px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 16px;
      margin: auto 0;
      width: 16px;
      height: 16px;
      background: url('/images/calendar.png') no-repeat;
      background-size: contain;
    }
    input {
      width: 100%;
    }
}   
    .search-container {
      position: relative;
      height: 45px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        margin: auto 0;
        width: 16px;
        height: 16px;
        background: url('/images/search.png') no-repeat;
        background-size: cover;
      }

      input {
        width: 100%;
      }
    }

    .job-filter {
      width: 235px;
      height: 45px;

      input::-webkit-input-placeholder { color: #022259; }

      .search-input {
        font-size: 18px;
        color: #022259;
        background: #FFFFFF;
        border: 2px solid #022259;
        border-radius: 13px;
        box-sizing: border-box;
        padding: 14px;
        width: 100%;
        height: 100%;
      }

      input[type=text] {
        padding-left: 50px;
      }
    }

    .multiselect-input {
      font-size: 18px;
      color: #022259;
      background: #FFFFFF;
      border: 2px solid #022259;
      border-radius: 13px;
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    .multiselect {
      width: 100%;
      height: 100%;
    }

    .multiselect-placeholder {
      color: #022259;
    }

    .multiselect-caret {
      color: #022259;
      border-color: #022259 transparent transparent;
    }

    .multiselect-clear {
      display: none;
    }

    .multiselect-option {
      &:hover {
        cursor: pointer;
      }
      img {
        padding-right: 10px;
      }
    }
  }

  .job-table {
    max-width: calc(100vw - 90px);

    .table-head, .table-body {
      width: 100%;

      .table-row {
        display: grid;
        grid-template-columns: 25% 24% 16% 15% 11% 9%;
        align-items: center;
        height: 100%;

        .table-created-at {
          
          span {
            padding-right: 52px;
          }
        }

      }
    }

    .table-head {
      margin-top: 35px;
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      background: #ffffff;
      border: 2px solid #EFF9FF;
      height: 50px;
      width: 100%;

      .table-row {

        .table-column {

          .column-sortable {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-self: center;
            align-items: center;
            padding: 10px;

            &:hover {
              cursor: pointer;
            }

            .column-sortable-icons {
              img {
                position: relative;
                top: -2px;
              }

              .column-title {
                padding-left: 10px;
              }
            }
          }

          .column {
            padding: 10px;
          }
        }
      }
    }

    .table-body {
      font-size: 18px;
      font-weight: 400;
      color: #022259;
      width: 100%;
      border: 2px solid #EFF9FF;

      .table-row {
        min-height: 60px;
        &:nth-child(odd) {
          background: #EFF9FF;
        }

        .table-column {
          padding: 0 10px;
          word-wrap: break-word;
          line-height: 22px;

          b {
            font-size: 18px;
            font-weight: 500;
          }

          &.status {
            div span {
              position: relative;
              top: 1px;
              padding-left: 10px;
            }

            div img {
              width: 30px;
              height: 30px;
            }
          }

          &.actions {
            display: grid;
            grid-template-columns: 20px 20px;
            grid-column-gap: 33px;
            align-items: center;

            img {
              width: 22px;
              height: 22px;
            }
            
            .delete {

              img {
                width: 15.6px;
                height: 22px;
              }
            }

            .tooltip {
              display:inline-block;
              position:relative;
            }

            .tooltip .top {
              min-width:100px;
              top:-20px;
              left:50%;
              transform:translate(-50%, -100%);
              padding:10px 20px;
              color:#022259;
              background-color:#ffffff;
              font-weight:normal;
              font-size:13px;
              border-radius:8px;
              position:absolute;
              z-index:99999999;
              box-sizing:border-box;
              border:1px solid #022259;box-shadow:0 1px 8px rgba(0,0,0,0.5);
              display:none;
            }

            .tooltip:hover .top {
              display:block;
            }

            .tooltip .top i {
              position:absolute;
              top:100%;
              left:50%;
              margin-left:-12px;
              width:24px;
              height:12px;
              overflow:hidden;
            }

            .tooltip .top i::after {
              content:'';
              position:absolute;
              width:12px;
              height:12px;
              left:50%;
              transform:translate(-50%,-50%) rotate(45deg);
              background-color: #ffffff;
              border:1px solid #022259;box-shadow:0 1px 8px rgba(0,0,0,0.5);
            }

            img {
              &:hover {
                cursor: pointer;
              }
              &.no-hover:hover {
                cursor: default;
              }
            }
          }
        }
      }

      .table-row-active {
        background: #d1d1d1 !important;
      }
    }

    .table-paging {
      display: grid;
      grid-template-columns: auto auto auto auto auto auto auto auto auto;
      justify-content: right;
      align-items: center;
      margin-top: 25px;

      .first-page, .prev-page, .next-page, .last-page {
        background: #022259;
        border-radius: 15px;
        padding: 7px;
        color: #ffffff;
        width: 15px;
        text-align: center;
        margin: 0 7px;

        &:hover {
          cursor: pointer;
        }
      }

      .page {
        width: 15px;
        color: #022259;
        margin: 0 7px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          font-weight: bold;
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
  .status-panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 900px;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 22px;
        height: 22px;
        padding-right: 12px;
      }

      span {
        color: #022259;
        font-size: 18px;
      }

      .item-count {
        padding-right: 12px;
        font-weight: bold;
      }
    }
  }

  .left {
    min-width: 69%;
    max-width: calc(100vw - 370px);
    padding-right: 10px;
  }
  
  .job-datatable {
    left: 0;
    top: 0;
    bottom: 0;
    right: 100px;
    display: grid;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    padding-top: 140px;
  }

  .show-details {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
   }

  .details-panel {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    margin-top: 175px;
    margin-left: 10px;
    width: 20%;
    min-width: 270px;
    max-width: 30%;
    display: flex;
    flex-direction: column;

    #drag {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2px;
      height: 100%;
      background: #EFF9FF;
      cursor: w-resize;
    }

    .details-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
      color: #022259;
      border: 2px solid #EFF9FF;
      height: 57px;

      .details-title {
        padding-left: 10px;
      }

      .icon-exit {
        padding-right: 10px;
        width: 16px;
        height: 16px;
      }
    }

    .details-body {
      background: #EFF9FF;
      height: 100%;
      color: #022259;
      border: 2px solid #EFF9FF;
      font-size: 18px;
      padding: 10px;
      overflow: scroll;

      .warning-buttons {
        width:100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
          width: 100px;
          background-color: #022259;
          border: 2px solid #022259;
          border-radius: 5px;
          color: #ffffff;
          cursor:pointer;
        }

        button:hover {
          background-color: #033181;
          border: 2px solid #033181;
        }
      }

      .details-item {
        padding-bottom: 35px;

        .title {
          font-weight: 500;
          font-size: 18px;
        }

        .item {
          padding-top: 5px;
          word-wrap: break-word;
        }

        .log-files {
          padding-top: 30px;
        }
      }

      .details-item:last-child {
        padding-bottom: 0px;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 53px;

    .pagination {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        color: #022259;
        padding-right: 5px; 
      }

      .pagination-limit {

        button {
          padding: 0;
          right: 5px;
          background-color: Transparent;
          background-repeat:no-repeat;
          border: none;
          cursor:pointer;
          overflow: hidden;
          outline:none;
          color: #022259;
        }

        button:focus {
          font-weight: bold;
        }
      }

      .pagination-list {
        display: flex;
        flex-direction: row;
        align-items: center;

        .round-range {

          .round-range-slider {
            width: 12px;
            height: 12px;
            position: relative;
            top: -10px;
            left: 12px;
          }

          .round-range-slider-title {
            position: relative;
            cursor:pointer;
          }
        }

        .current-page {
          font-weight: bold;
          position: relative;
          left: 10px;
        }

        img {
          width: 20px;
          height: 20px;
        }

        .button-left {
          position: relative;
          left: 10px;
          padding-right: 35px;
          padding-left: 5px;
          transform: scale(-1, 1);
          cursor:pointer;
        }

        .button-right {
          cursor:pointer;
        }
      }
    }
  }
  .slidecontainer {
    width: 90px;
  }

.slider {
  float: right;
  -webkit-appearance: none;
  width: 90px;
  height: 11px;
  border-radius: 5px;
  background: #cce7f8;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #022259;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #022259;
  cursor: pointer;
}

.footer {
  padding-bottom: 20px;
}

}

@media screen and (min-width: 1400px) and (max-width: 1910px){
  template {
    width: 100%;

    .job-container {
      width: 100%;
      padding-left: 45px;
    }
  }

  .filter-container {
    width: 850px;
  }

  .job-filter {
    width: 200px !important;
    padding-right: 10px;
  }

  .job-filter:last-child {
    padding-right: 0px;
  }
}

@media screen and (max-width: 1400px){
  .job-heading {
    flex-wrap: wrap;
  }

  .filter-container {
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .job-filter {
    margin-bottom: 20px;
  }

  .status-panel {
    flex-wrap: wrap;

    .item {
      margin-bottom: 10px;
    }
  }

  .pagination {
    flex-direction: column !important;
    align-items: center;
    justify-content: space-between;
    margin-left: 10px;
  }

  .footer {
    padding-bottom: 0;
  }
}

@media screen and (min-width: 1920px){
  .job-heading {
    width: 100%;
  }

  .job-table {
    width: 100%;
  }
}

@media screen and (max-width: 1400px){
  .table-head{
    margin-top: 115px !important;
  }

  .job-table {
    width: 1470px !important;
  }

  .job-datatable {
    width: 100%;
    overflow-x: auto;
  }

  .details-panel {
    margin-top: 255px !important;
  }
}

@media screen and (max-width: 1024px){
  .table-head {
    margin-top: 176px !important;
  }

  .job-table {
    width: 1470px !important;
  }

  .job-datatable {
    width: 100%;
    overflow-x: auto;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
